
































import { Component, Prop, Vue } from 'vue-property-decorator';

import { deleteProfileAvatarV2, postProfileAvatarV2 } from '@minddocdev/lib-web/api/services/users';

import { addBreadcrumb, captureException } from '@sentry/vue';

@Component
export default class ProfilePicture extends Vue {
  @Prop({ required: false, type: String, default: '' })
  label!: string;

  // Maximum allowed file size of 7mb.
  private readonly maxFileSizeMB = 7;
  private readonly maxFileSize = this.maxFileSizeMB * 1024 * 1024;
  private readonly defaultImage =
    'https://static.minddoc.com/takeshi/frontend/shared/custom-icons/add-avatar.png';

  avatarSize = 60;
  uploading = false;
  deleting = false;
  profilePicture = '';
  avatarProfile = '';

  get pictureUrl() {
    return this.avatarProfile || this.defaultImage;
  }

  notifyTooLarge() {
    this.$notify({
      type: 'warn',
      text: this.$t('notifications.sizeLimit', {
        size: this.maxFileSizeMB,
      }) as string,
      duration: -1,
    });
  }

  notifySuccess() {
    this.$notify({
      type: 'success',
      text: this.$t('notifications.success') as string,
    });
  }

  notifyError() {
    this.$notify({
      type: 'error',
      text: this.$t('notifications.error') as string,
      duration: -1,
    });
  }

  async uploadProfilePicture() {
    const fileInput = this.$refs.file as HTMLInputElement;
    if (!fileInput.files?.length) {
      return;
    }
    const file = fileInput.files[0];
    if (file.size >= this.maxFileSize) {
      this.notifyTooLarge();
      return;
    }
    /* istanbul ignore next */
    addBreadcrumb({
      category: 'profile-picture',
      message: 'Uploading profile picture.',
    });
    this.uploading = true;
    try {
      this.profilePicture = await postProfileAvatarV2(file);
      this.notifySuccess();
    } catch (error) {
      this.notifyError();
      captureException(error);
    } finally {
      this.uploading = false;
    }
  }

  async deleteProfilePicture() {
    /* istanbul ignore next */
    addBreadcrumb({
      category: 'profile-picture',
      message: 'Deleting profile picture.',
    });
    this.deleting = true;
    try {
      await deleteProfileAvatarV2();
    } catch (error) {
      this.notifyError();
      captureException(error);
    } finally {
      this.deleting = false;
    }
  }
}
