import { ValidationObserver } from 'vee-validate';

/**
 * Trigger validation on a vee-validate observer and scrolls into the first input with error if any.
 */
export async function validateForm(
  validationObserver: InstanceType<typeof ValidationObserver>): Promise <boolean> {
  const response = await validationObserver.validate();
  const errors = Object.entries(validationObserver.refs)
    .map(([key, value]) => ({ key, value }))
    .filter((error) => {
      if (!error || !error.value || !error.value.failedRules) return false;
      return Object.keys(error.value.failedRules).length > 0;
    });
  if(errors && errors.length > 0) {
    validationObserver.refs[errors[0].key].$el.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  }
  return response;
}
