



















































































































































import { Component, Prop, Ref, Vue } from 'vue-property-decorator';

import { Language } from '@minddocdev/lib-common/therapy';
import { Role } from '@minddocdev/lib-common/users';
import MDFormInput from '@minddocdev/lib-components/components/MDFormInput.vue';
import MDInputCheckbox from '@minddocdev/lib-components/components/MDInputCheckbox.vue';
import MDSelect from '@minddocdev/lib-components/components/MDSelect.vue';
import { postPractitioner } from '@minddocdev/lib-web/api/services/users';
import { validateForm } from '@minddocdev/lib-web/validation/form';
import ProfilePicture from '@minddocdev/ui-accounts/components/onboarding/ProfilePicture.vue';

import { addBreadcrumb, captureException } from '@sentry/vue';
import { ValidationObserver } from 'vee-validate';

@Component({
  components: {
    MDFormInput,
    MDInputCheckbox,
    MDSelect,
    PasswordInput: () => import('@minddocdev/lib-web/components/inputs/PasswordInput.vue'),
    ProfilePicture,
  },
})
export default class PractitionerOnboarding extends Vue {
  @Prop({ required: true, type: String })
  email!: string;
  @Prop({ required: true, type: Array })
  roles!: Role;

  @Ref()
  readonly observer!: InstanceType<typeof ValidationObserver>;

  tAndC = false;
  practitioner: RestUsers.PractitionerProfileResDto | null = null;
  model: RestUsers.OnboardPractitionerReqDto = {
    password: '',
    profile: {
      address: {
        city: '',
        street: '',
        zip: '',
      },
      firstName: '',
      lastName: '',
      dateOfBirth: '',
      introduction: '',
      gender: undefined,
      title: undefined,
      diseaseSpecializations: [],
      languages: [Language.German],
      therapySpecializations: [],
      treatmentServices: [],
    },
  };

  get isTherapyOwner() {
    return this.roles.includes(Role.TherapySessionOwner);
  }
  get isSubmitDisabled() {
    return !this.tAndC || (this.isTherapyOwner && this.model.profile.languages.length === 0);
  }

  toggleArrayItem(array: string[], item: string) {
    const index = array.indexOf(item);
    if (index > -1) {
      array.splice(index, 1);
    } else {
      array.push(item);
    }
  }

  async submitOnboarding() {
    const isValid = await validateForm(this.observer);
    if (!isValid) return;
    try {
      addBreadcrumb({
        category: 'onboarding',
        message: 'Completing diagnostician onboarding',
      });
      await postPractitioner(this.model);
      this.$emit('completed');
    } catch (error) {
      /* istanbul ignore next */
      if (error?.response?.status === 401) return;
      captureException(error);
      this.$notify({
        type: 'error',
        text: this.$t('errors.unexpected') as string,
        duration: -1,
      });
    }
  }
}
