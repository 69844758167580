













































// TODO: Delete this file after the due date is passed
import { Component, Prop, Ref, Vue } from 'vue-property-decorator';

import { MDFormInput, MDInputCheckbox, MDProgressSpinner, Modal } from '@minddocdev/lib-components';
import { getLatestPolicies } from '@minddocdev/lib-web/api/services/users';
import { getWebRedirectURL } from '@minddocdev/lib-web/browser/url.utils';

import { captureException } from '@sentry/vue';
import { ValidationObserver } from 'vee-validate';

@Component({
  components: {
    Modal,
    MDFormInput,
    MDProgressSpinner,
    MDInputCheckbox,
  },
})
export default class TransferOfConsentModal extends Vue {
  @Ref('validationObserver')
  readonly validationObserver!: InstanceType<typeof ValidationObserver>;

  @Prop({ required: true })
  onSubmit!: (policiesIds: string[]) => Promise<any>;

  loading = true;
  dataProtectionConsent = false;
  policyUuids: string[] = [];
  isExpanded = false;
  urlPrefix = getWebRedirectURL(window.location.hostname);

  mounted() {
    this.fetchPolicies();
  }

  get primaryAction() {
    return {
      text: this.$t('buttons.primaryAction'),
      action: this.handleSubmit,
      disabled: () => !this.dataProtectionConsent,
    };
  }

  toggleExpanded() {
    this.isExpanded = !this.isExpanded;
  }

  async fetchPolicies() {
    try {
      this.loading = true;

      const policies = await getLatestPolicies([
        'transfer_of_business',
        'transfer_of_patient_files',
      ]);

      this.policyUuids = policies
        .filter((policy) =>
          ['transfer_of_business', 'transfer_of_patient_files'].includes(policy.name),
        )
        .map((policy) => policy.uuid);

      this.loading = false;
    } catch (e) {
      this.loading = false;

      captureException(e);

      this.$notify({
        type: 'error',
        text: this.$t('error') as string,
        duration: -1,
      });
    }
  }

  async handleSubmit() {
    this.loading = true;

    try {
      await this.onSubmit(this.policyUuids);
    } catch (e) {
      captureException(e);

      this.$notify({
        type: 'error',
        text: this.$t('error') as string,
        duration: -1,
      });
    }
  }
}
