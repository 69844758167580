








































































































import { Component, Ref, Vue } from 'vue-property-decorator';

import { isConsentFlow } from '@minddocdev/lib-common/utils';
import MDFormInput from '@minddocdev/lib-components/components/MDFormInput.vue';
import MDInputCheckbox from '@minddocdev/lib-components/components/MDInputCheckbox.vue';
import MDSelect from '@minddocdev/lib-components/components/MDSelect.vue';
import { postPatient } from '@minddocdev/lib-web/api/services/users';
import { validateForm } from '@minddocdev/lib-web/validation/form';
import ProfilePicture from '@minddocdev/ui-accounts/components/onboarding/ProfilePicture.vue';
import TransferOfConsentModal from '@minddocdev/ui-accounts/components/onboarding/TransferOfConsentModal.vue';

import { addBreadcrumb, captureException } from '@sentry/vue';
import { ValidationObserver } from 'vee-validate';

@Component({
  components: {
    MDFormInput,
    MDInputCheckbox,
    MDSelect,
    PasswordInput: () => import('@minddocdev/lib-web/components/inputs/PasswordInput.vue'),
    ProfilePicture,
    TransferOfConsentModal,
  },
})
export default class PatientOnboarding extends Vue {
  @Ref()
  readonly observer!: InstanceType<typeof ValidationObserver>;

  private readonly googleApiKey = process.env.VUE_APP_GOOGLE_MAPS_API_KEY;

  formattedAddress = '';
  model: RestUsers.OnboardPatientReqDto = {
    password: '',
    profile: {
      address: {
        city: '',
        street: '',
        zip: '',
      },
      firstName: '',
      lastName: '',
      dateOfBirth: '',
      phoneNumber: '',
    },
    signedPolicies: [],
  };
  tAndC = false;
  showTransferOfConsent = false;
  consentFlow = false;

  mounted() {
    this.consentFlow = this.calculateDueDate(); // Update consentFlow after mounting
  }

  private calculateDueDate() {
    return isConsentFlow(this.$route.query); // Access this.$route.query here
  }

  addressChanged(address: UI.Address) {
    this.model.profile.address!.street = `${address.street} ${address.number}`;
    this.model.profile.address!.city = address.city;
    this.model.profile.address!.zip = address.zip;
  }

  async handleContinue() {
    if (!this.consentFlow) {
      this.onSubmit();
    }
    const isValid = await validateForm(this.observer);
    if (isValid && this.consentFlow) {
      this.showTransferOfConsent = true;
    }
  }

  async onSubmit(policiesIds: string[] = []) {
    try {
      const isValid = await validateForm(this.observer);
      if (!isValid) return;

      addBreadcrumb({
        message: 'Completing patient onboarding',
        category: 'onboarding',
      });
      this.model.signedPolicies = [...policiesIds];
      if (!this.model.signedPolicies?.length) {
        delete this.model.signedPolicies;
      }
      await postPatient(this.model);
      this.showTransferOfConsent = false;

      this.$emit('completed');
    } catch (error) {
      /* istanbul ignore next */
      if (error?.response?.status === 401) return;
      /* istanbul ignore next */
      this.$notify({
        type: 'error',
        text: this.$t('de.errors.unexpected') as string,
        duration: -1,
      });
      captureException(error);
    }
  }
}
