
















import { VueConstructor } from 'vue';
import { Component, Vue } from 'vue-property-decorator';

import { Jwt, UserGroup } from '@minddocdev/lib-common/users';
// Profiles
import MDProgressSpinner from '@minddocdev/lib-components/components/MDProgressSpinner.vue';
import { createInterceptors } from '@minddocdev/lib-web/api/helpers';
import { postInvitation } from '@minddocdev/lib-web/api/services/users';
import { getAppForGroup } from '@minddocdev/lib-web/browser/url.utils';
import Patient from '@minddocdev/ui-accounts/components/onboarding/Patient.vue';
import Practitioner from '@minddocdev/ui-accounts/components/onboarding/Practitioner.vue';
import OTPExpired from '@minddocdev/ui-accounts/components/OTPExpired.vue';

import { addBreadcrumb, captureException } from '@sentry/vue';
import jwtDecode from 'jwt-decode';

@Component({
  components: {
    MDProgressSpinner,
    OTPExpired,
    Patient,
    Practitioner,
  },
})
export default class Onboarding extends Vue {
  loading = true;
  isOTPValid = false;
  currentComponent: VueConstructor | null = null;
  accessToken = '';
  decodedAccessToken: Jwt | null = null;
  email = '';
  roles: string[] = [];

  created() {
    this.fetchInvite(this.$route.params.otp);
  }

  async fetchInvite(otp?: string) {
    try {
      if (!otp) {
        this.isOTPValid = false;
      } else {
        /* istanbul ignore next */
        addBreadcrumb({
          message: 'Validating OTP for onboarding',
          category: 'onboarding',
        });
        const decodedOtp = JSON.parse(atob(otp)) as any;
        this.email = decodedOtp.email;
        this.roles = decodedOtp.roles;
        this.accessToken = (await postInvitation(otp)).accessToken;
        this.decodedAccessToken = jwtDecode(this.accessToken);
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        createInterceptors(() => this.accessToken, (() => {}) as any);
        this.isOTPValid = true;
      }
    } catch (e) {
      /* istanbul ignore next */
      if (e.response?.status >= 500) {
        captureException(e);
      }
    } finally {
      this.loading = false;
      this.updateComponent();
    }
  }

  updateComponent() {
    if (!this.isOTPValid) {
      this.currentComponent = OTPExpired;
      return;
    }
    switch (this.decodedAccessToken!.group) {
      case UserGroup.Patient:
        this.currentComponent = Patient;
        break;
      case UserGroup.Practitioner:
        this.currentComponent = Practitioner;
        break;
      default:
        this.currentComponent = OTPExpired;
        break;
    }
  }

  redirectToPlatform() {
    window.location.href = getAppForGroup(this.decodedAccessToken!.group, window.location.hostname);
  }
}
